import React from 'react';
import './HelperSquare.css';

const HelperSquare = () => {
    return (
        <div className="helper-square-container">
            <div className="color-explanation">
                <div className="color-square green"></div>
                <p>Correct</p>
            </div>
            <div className="color-explanation">
                <div className="color-square dark-yellow"></div>
                <p>The value is higher than what you entered</p>
            </div>
            <div className="color-explanation">
                <div className="color-square light-yellow"></div>
                <p>The value is lower than what you entered</p>
            </div>
            <div className="color-explanation">
                <div className="color-square red"></div>
                <p>There is no match between what you selected and the value</p>
            </div>
        </div>
    );
};

export default HelperSquare;
