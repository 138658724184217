// RevealHints.js
import React from 'react';
import './RevealHints.css';
import { determineGender } from '../../helpers/Gender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const RevealHints = ({ attempts, player }) => {
    const playerGender = determineGender(player.league, player.name);
    const playerImageUrl = player.altimage ? `https://cdn.futwiz.com/assets/img/fc24/faces/${player.altimage}.png`
        : `https://cdn.futwiz.com/assets/img/fc24/faces/${player.pid}.png`;
        const isGenderRevealed = attempts.length >= 5;
        const isRatingRevealed = attempts.length >= 10;
        const isFaceRevealed = attempts.length >= 15;

        const isGenderRevealedTriesLeft = 5 - attempts.length;
        const isRatingRevealedTriesLeft = 10 - attempts.length;
        const isFaceRevealedTriesLeft = 15 - attempts.length;
    
        return (
            <div className="hints-container">
                <div className="hint">
                    {isGenderRevealed ? null : <FontAwesomeIcon icon={faLock} className="hint-icon" />}
                    <div className="hint-detail">{isGenderRevealed ? playerGender : 'Gender Clue'}</div>
                    <div className="hint-attempts">{isGenderRevealed ? 'Gender' : `in ${isGenderRevealedTriesLeft} tries`}</div>
                </div>
                <div className="hint">
                    {isRatingRevealed ? null : <FontAwesomeIcon icon={faLock} className="hint-icon" />}
                    <div className="hint-detail">{isRatingRevealed ? player.rating : 'Rating Clue'}</div>
                    <div className="hint-attempts">{isRatingRevealed ? 'Rating' : `in ${isRatingRevealedTriesLeft} tries`}</div>
                </div>
                <div className="hint">
                    {isFaceRevealed ? null : <FontAwesomeIcon icon={faLock} className="hint-icon" />}
                    <div className="hint-detail">{isFaceRevealed ? <img src={playerImageUrl} alt={player.name} className="player-image" /> : 'Face Clue'}</div>
                    <div className="hint-attempts">{isFaceRevealed ? 'Face' : `in ${isFaceRevealedTriesLeft} tries`}</div>
                </div>
            </div>
        );
    };
    
    export default RevealHints;
