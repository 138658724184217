import React, { useState } from "react";
import "./CopyToClipboard.css";

const CopyToClipboard = ({ attemptsLength }) => {
    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboardFallback = text => {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Evita el desplazamiento al elemento invisible en la página
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.opacity = "0";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            setCopySuccess("Copied to clipboard!");
        } catch (err) {
            setCopySuccess("Failed to copy!");
        }

        document.body.removeChild(textArea);
    };

    const copyToClipboard = () => {
        const text = `
        I managed to guess the correct player in just 🔥 ${attemptsLength} 🔥 tries! 🏆
        Try it at utdle.com ! 🚀 #UTdle
        `;

        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => setCopySuccess("Copied to clipboard!"))
                .catch(() => copyToClipboardFallback(text));
        } else {
            copyToClipboardFallback(text);
        }
    };

    return (
        <div className="copy-to-clipboard">
            <button onClick={copyToClipboard} style={{ backgroundColor: 'green' }}>
                Share Victory
            </button>
            {copySuccess && <div className="copy-success-message">{copySuccess}</div>}
        </div>
    );
};

export default CopyToClipboard;