// FeedbackSquare.js
import React from "react";
import "./FeedbackSquare.css";

const FeedbackSquare = ({ attribute, value, feedback }) => {
    let content;
    let className = '';

    // Define el className y el contenido según el feedback
    switch (feedback) {
        case 'equal':
            className = 'correct';
            content = <span>{value}</span>;
            break;
        case 'higher':
            className = 'higher';
            content = (
                <>
                    <span className="icon">↑</span>
                    <span>{value}</span>
                </>
            );
            break;
        case 'lower':
            className = 'lower';
            content = (
                <>
                    <span className="icon">↓</span>
                    <span>{value}</span>
                </>
            );
            break;
        case true:
            className = 'correct';
            content = <span>{value}</span>;
            break;
        case false:
            className = 'incorrect';
            content = <span>{value}</span>;
            break;
        default:
            content = <span>{value}</span>;
            break;
    }

    // Añade la estrella para los atributos 'Weak Foot' y 'Skill Moves'
    if (attribute === 'Weak Foot' || attribute === 'Skill Moves') {
        content = (
            <>
                {content}
                <span className="star"> ★</span>
            </>
        );
    }

    // Si el atributo es uno de los tipos de imagen, cambia el contenido a una imagen
    if (['Club', 'Nation', 'League', 'Rarity'].includes(attribute) && typeof feedback === 'boolean') {
        content = <img src={value} className="img-square" alt={`${attribute} logo`} />;
    }

    if (attribute === 'PlayerImg') {
        content = <img src={value} className="img-square-player" alt={`${attribute} logo`} />;
    }

    return (
        <div className={`feedback-square ${className}`}>
            {content}
        </div>
    );
};

export default FeedbackSquare;
