// WinModal.js

import React, { useState, useEffect } from 'react';
import Counter from "../Counter/Counter";
import "./WinModal.css";
import { obtenerValorFinal } from "../../helpers/ObtenerValorFinal.js";
import ShareVictory from "../CopyToClipboard/ShareVictory.js";

const WinModal = ({ player, onClose, showStats, streak, currentDay, attempts }) => {
    const { name, rating, position, nation, league, club, altimage, pid } = player;
    const [textColor, setTextColor] = useState('#ffffff');
    const [backgroundColor, setBackgroundColor] = useState('#000000');
    const attemptsLength = attempts.length;

    const playerImageUrl = altimage ? `https://cdn.futwiz.com/assets/img/fc24/faces/${altimage}.png`
        : `https://cdn.futwiz.com/assets/img/fc24/faces/${pid}.png`;

    const [rarity, setRarity] = useState(null);

    useEffect(() => {
        if (player && player.cardtype) {
            const fetchedRarity = obtenerValorFinal(player.cardtype);
            setRarity(fetchedRarity);
        }
    }, [player]); // Dependency array includes `player` to re-run the effect when `player` changes

    useEffect(() => {
        const img = new Image();
        img.src = `https://cdn.futwiz.com/assets/img/fc24/items/${rarity}.png`;
        img.crossOrigin = 'Anonymous'; // Avoid CORS issues
        img.onload = () => {
            // Create canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            // Get image data
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const color = getDominantColor(imageData.data); // Implement this function based on your needs

            // Calculate and set the contrasting color
            setBackgroundColor(`rgb(${color.r},${color.g},${color.b})`);
            setTextColor(getContrastingColor(color)); // Implement this function based on your needs
        };
    }, [rarity]);

    if (!player || rarity === null) {
        // Render nothing or a loading indicator until `player` and `rarity` are available
        return null; // or return <LoadingIndicator />;
    }

    function getDominantColor(data) {
        if (!data || data.length === 0) return null;

        const rgb = { r: 0, g: 0, b: 0 };

        for (let i = 0; i < data.length; i += 4) {
            rgb.r += data[i];
            rgb.g += data[i + 1];
            rgb.b += data[i + 2];
        }

        const count = data.length / 4;
        rgb.r = ~~(rgb.r / count);
        rgb.g = ~~(rgb.g / count);
        rgb.b = ~~(rgb.b / count);

        return rgb;

    }

    function getContrastingColor(rgb) {
        if (!rgb) return '#000000';

        // Calculate the YIQ equation from RGB
        const yiq = ((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000;

        // Adjust the threshold to be lower to return darker colors for lighter backgrounds
        const threshold = 128; // You might lower this if it still doesn't give you the desired contrast

        // Depending on the YIQ value, return either a dark color for light backgrounds,
        // or a light color for dark backgrounds.
        return (yiq >= threshold) ? '#000000' : '#ffffff'; // Switch to '#000000' for lighter backgrounds
    }
    return (
        <div className="win-modal-backdrop">
            <div className="win-modal">
                <header className="win-modal-header">
                    <h2>Congratulations! - You win</h2>
                    <button onClick={onClose}>X</button>
                </header>
                <div className="win-modal-content">
                    <div className="player-card-img">
                        <img src={`https://cdn.futwiz.com/assets/img/fc24/items/${rarity}.png`} alt={`${name}`} className="card-background" />
                        {playerImageUrl === `https://cdn.futwiz.com/assets/img/fc24/faces/${pid}.png` ? <img src={playerImageUrl} alt={`${name}`} className="player-face-0" /> : <img src={playerImageUrl} alt={`${name}`} className="player-face" />}
                        <div className="player-overlay">
                            <div className="player-rating-img" style={{ color: textColor }}>{rating}</div>
                            <div className="player-position-img" style={{ color: textColor }}>{position}</div>
                            <h2 className="player-name-img" style={{ color: textColor, backgroundColor: backgroundColor }}>{name}</h2>
                            <div className="player-badges">
                                <img src={`https://cdn.futwiz.com/assets/img/fc24/flags/${nation}.png`} alt="Nation" />
                                <img src={`https://cdn.futwiz.com/assets/img/fc24/leagues/${league}.png`} alt="League" />
                                <img src={`https://cdn.futwiz.com/assets/img//fc24//badges/${club}.png`} alt="Club" />
                            </div>
                        </div>
                    </div>
                    <h3>{player.name} - {player.rating}</h3>
                    <p className="rarity">{player.rarityName}</p>
                    <button onClick={showStats}>View Statistics</button>
                    <ShareVictory attemptsLength={attemptsLength} />
                    <Counter currentDay={currentDay} />
                    <p className="final-message">Play tomorrow and keep your streak of {streak} 🔥 guesses!</p>
                </div>
            </div>
        </div>
    );
};

export default WinModal;
