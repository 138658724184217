import React from "react";

import "./TwitterShare.css";

const TwitterShare = ({ playerStats }) => {
    return (
        <div className="twitter-share">
            <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`
            My #UTdle statistics:
            🏆 Games Won: ${playerStats.totalWins}
            🔍 Average Guesses: ${playerStats.averageAttempts.toFixed(1)}
            🌟 Best Guess: ${playerStats.bestAttempts}
            ⚡ Current Streak: ${playerStats.streak}
            🔥 Max Streak: ${playerStats.maxStreak}
            https://utdle.com
            `)}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <button>Share on Twitter</button>
            </a>
        </div>
    );
}

export default TwitterShare;
