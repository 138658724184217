import React from 'react';
import { SocketProvider } from './context/SocketContext';
import Game from './components/Game/Game';
import './App.css';

function App() {
    return (
        <SocketProvider>
            <Game />
        </SocketProvider>
    );
}

export default App;