import React, { useState, useEffect } from 'react';
import './PlayerCard.css';
import { obtenerValorFinal } from '../../helpers/ObtenerValorFinal.js';

const PlayerCard = ({ player }) => {
    const { name, cardtype, rating, position, nation, league, club, altimage, pid, att1, att2, att3, att4, att5, att6 } = player;

    const rarity = obtenerValorFinal(cardtype);

    const [textColor, setTextColor] = useState('#ffffff');
    const [backgroundColor, setBackgroundColor] = useState('#000000');

    const playerImageUrl = altimage ? `https://cdn.futwiz.com/assets/img/fc24/faces/${altimage}.png`
        : `https://cdn.futwiz.com/assets/img/fc24/faces/${pid}.png`;

    useEffect(() => {
        const img = new Image();
        img.src = `https://cdn.futwiz.com/assets/img/fc24/items/${rarity}.png`;
        img.crossOrigin = 'Anonymous'; // Avoid CORS issues
        img.onload = () => {
            // Create canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            // Get image data
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const color = getDominantColor(imageData.data); // Implement this function based on your needs

            // Calculate and set the contrasting color
            setBackgroundColor(`rgb(${color.r},${color.g},${color.b})`);
            setTextColor(getContrastingColor(color)); // Implement this function based on your needs
        };
    }, [rarity]);

    function getDominantColor(data) {
        if (!data || data.length === 0) return null;

        const rgb = { r: 0, g: 0, b: 0 };

        for (let i = 0; i < data.length; i += 4) {
            rgb.r += data[i];
            rgb.g += data[i + 1];
            rgb.b += data[i + 2];
        }

        const count = data.length / 4;
        rgb.r = ~~(rgb.r / count);
        rgb.g = ~~(rgb.g / count);
        rgb.b = ~~(rgb.b / count);

        return rgb;

    }

    function getContrastingColor(rgb) {
        if (!rgb) return '#000000';
    
        // Calculate the YIQ equation from RGB
        const yiq = ((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000;
    
        // Adjust the threshold to be lower to return darker colors for lighter backgrounds
        const threshold = 128; // You might lower this if it still doesn't give you the desired contrast
    
        // Depending on the YIQ value, return either a dark color for light backgrounds,
        // or a light color for dark backgrounds.
        return (yiq >= threshold) ? '#000000' : '#ffffff'; // Switch to '#000000' for lighter backgrounds
    }    

    return (
        <div key={player} className="player-card">
            <div className="player-card-img">
                <img src={`https://cdn.futwiz.com/assets/img/fc24/items/${rarity}.png`} alt={`${name}`} className="card-background" />
                {playerImageUrl === `https://cdn.futwiz.com/assets/img/fc24/faces/${pid}.png` ? <img src={playerImageUrl} alt={`${name}`} className="player-face-0" /> : <img src={playerImageUrl} alt={`${name}`} className="player-face" />}
                <div className="player-overlay">
                    <div className="player-rating-img" style={{ color: textColor}}>{rating}</div>
                    <div className="player-position-img" style={{ color: textColor}}>{position}</div>
                    <h2 className="player-name-img" style={{ color: textColor, backgroundColor: backgroundColor }}>{name}</h2>
                    <div className="player-badges">
                        <img src={`https://cdn.futwiz.com/assets/img/fc24/flags/${nation}.png`} alt="Nation" />
                        <img src={`https://cdn.futwiz.com/assets/img/fc24/leagues/${league}.png`} alt="League" />
                        <img src={`https://cdn.futwiz.com/assets/img//fc24//badges/${club}.png`} alt="Club" />
                    </div>
                </div>
            </div>
            <div className="player-info">
                <h2 className="player-name">{name} - {rating}</h2>
                <div className="player-stats">
                    <div className="player-stat">
                        <span className="stat-name">PAC </span>
                        <span className="stat-value">{att1}</span>
                    </div>
                    <div className="player-stat">
                        <span className="stat-name">SHO </span>
                        <span className="stat-value">{att2}</span>
                    </div>
                    <div className="player-stat">
                        <span className="stat-name">PAS </span>
                        <span className="stat-value">{att3}</span>
                    </div>
                    <div className="player-stat">
                        <span className="stat-name">DRI </span>
                        <span className="stat-value">{att4}</span>
                    </div>
                    <div className="player-stat">
                        <span className="stat-name">DEF </span>
                        <span className="stat-value">{att5}</span>
                    </div>
                    <div className="player-stat">
                        <span className="stat-name">PHY </span>
                        <span className="stat-value">{att6}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayerCard;



















