import React, { useState, useEffect, useRef } from 'react';
import PlayerCard from '../PlayerCard/PlayerCard';
import './Search.css';
import soccerBallIcon from '../../assets/football.png'; // Add your soccer ball icon path here

function Search({ onSelectPlayer }) {
    const [query, setQuery] = useState('');
    const [players, setPlayers] = useState([]);
    const dropdownRef = useRef(null);

    // This state is used to control the visibility of the dropdown.
    const [dropdownVisible, setDropdownVisible] = useState(false);

    // This effect sets up an event listener for clicks outside of the dropdown to close it.
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handlePlayerClick = (player) => {
        onSelectPlayer(player);
        setQuery(''); // This will clear the input field
        setDropdownVisible(false); // This will close the dropdown
    };

    const searchPlayers = async () => {
        if (query.length < 3) {
            setPlayers([]);
            setDropdownVisible(false);
            return;
        }

        try {
            const response = await fetch(`https://utdle-3zgcdoayfq-uc.a.run.app/search/${encodeURIComponent(query)}`);
            const data = await response.json();
            setPlayers(data || []);
            setDropdownVisible(true); // Show the dropdown when there are results
        } catch (error) {
            console.error('Error fetching the players:', error);
        }
    };

    return (
        <div className="search-container">
            <div className="search-field">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyUp={searchPlayers}
                    placeholder="Search for a player..."
                />
                <button className="search-btn" onClick={searchPlayers}>
                    <img src={soccerBallIcon} alt="Search" />
                </button>
            </div>
            {dropdownVisible && (
                <div className="player-cards-container">
                    {Array.isArray(players) ? (
                        players.map((player) => (
                            <div className='player-card-ind' key={player.lineid} onMouseDown={() => handlePlayerClick(player)}>
                                <PlayerCard player={player} />
                            </div>
                        ))
                    ) : (
                        <div>Error showing players. Try in a few moments</div>
                    )}
                </div>
            )}
        </div>
    );

}

export default Search;
