import React from 'react';
import './Modal.css';

const Modal = ({ title, children, onClose }) => {
    return (
        <div className="modal-backdrop">
            <div className="modal">
                <header className="modal-header">
                    <h2>{title}</h2>
                    <button onClick={onClose}>X</button>
                </header>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
