import React, { useState } from "react";
import "./CopyToClipboard.css";

const CopyToClipboard = ({ playerStats }) => {
    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboardFallback = text => {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Evita el desplazamiento al elemento invisible en la página
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.opacity = "0";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            setCopySuccess("Copied to clipboard!");
        } catch (err) {
            setCopySuccess("Failed to copy!");
        }

        document.body.removeChild(textArea);
    };

    const copyToClipboard = () => {
        const text = `
            My #UTdle statistics:
            🏆 Games Won: ${playerStats.totalWins}
            🔍 Average Guesses: ${playerStats.averageAttempts.toFixed(1)}
            🌟 Best Guess: ${playerStats.bestAttempts}
            ⚡ Current Streak: ${playerStats.streak}
            🔥 Max Streak: ${playerStats.maxStreak}
            https://utdle.com
        `;

        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => setCopySuccess("Copied to clipboard!"))
                .catch(() => copyToClipboardFallback(text));
        } else {
            copyToClipboardFallback(text);
        }
    };

    return (
        <div className="copy-to-clipboard">
            <button onClick={copyToClipboard}>
                Copy to Clipboard
            </button>
            {copySuccess && <div className="copy-success-message">{copySuccess}</div>}
        </div>
    );
};

export default CopyToClipboard;
