import React, { useState, useEffect, useCallback } from "react";
import "./Game.css";
import { useSocket } from '../../context/SocketContext';
import logo from "../../assets/logo.png";
import iconStats from "../../assets/growth.png";
import iconHowToPlay from "../../assets/knowledge.png";
import Search from "../Search/Search";
import FeedbackSquare from "../FeedbackSquare/FeedbackSquare";
import Counter from "../Counter/Counter";
import HelperSquare from "../HelperSquare/HelperSquare";
import RevealHints from "../RevealHints/RevealHints";
import Modal from "../Modal/Modal";
import WinModal from "../Modal/WinModal";
import TwitterShare from "../TwitterShare/TwitterShare";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import { obtenerValorFinal } from "../../helpers/ObtenerValorFinal.js";

const START_DATE = new Date(Date.UTC(2023, 11, 26));

function Game() {
    const socket = useSocket();
    const [gameCompleted, setGameCompleted] = useState(false);
    const [currentDay, setCurrentDay] = useState(getDayDifference(START_DATE));
    const [winnerPlayer, setWinnerPlayer] = useState({});
    const [attempts, setAttempts] = useState([]);
    const [showStats, setShowStats] = useState(false);
    const [showHowToPlay, setShowHowToPlay] = useState(false);
    const [showWinModal, setShowWinModal] = useState(false);
    const [countCompletedGames, setCountCompletedGames] = useState(0);

    const [playerStats, setPlayerStats] = useState({
        totalWins: 0,
        averageAttempts: 0,
        bestAttempts: 'NA',
        streak: 0,
        maxStreak: 0
    });

    function getDayDifference(startDate) {
        const now = new Date();
        const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
        const difference = nowUTC - startDate;
        return Math.floor(difference / (1000 * 60 * 60 * 24)) + 1; // +1 para comenzar desde el día 1
    }

    useEffect(() => {
        // Actualiza `currentDay` al montar el componente
        setCurrentDay(getDayDifference(START_DATE));

        // Establece un intervalo para verificar si ha pasado un nuevo día cada minuto
        const interval = setInterval(() => {
            setCurrentDay(getDayDifference(START_DATE));
        }, 60000); // 60 * 1000 milisegundos

        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(interval);
    }, []);

    async function fetchDailyPlayerCard() {
        try {
            const response = await fetch('https://utdle-3zgcdoayfq-uc.a.run.app/daily-player-card');
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching daily player card:', error);
        }
    }

    useEffect(() => {
        fetchDailyPlayerCard().then(data => {
            if (data && !data.message) {
                setWinnerPlayer(data);
            }
        });
    }, []);

    useEffect(() => {
        fetch('https://utdle-3zgcdoayfq-uc.a.run.app/get-completed-count')
            .then(response => response.json())
            .then(data => setCountCompletedGames(data.countCompletedGames))
            .catch(error => console.error('Error:', error));
    }, [attempts]);

    const resetGame = useCallback(() => {
        setAttempts([]);
        setGameCompleted(false);
        setShowWinModal(false);
        setCountCompletedGames(0);
    }, []);

    const handleStatsClick = () => {
        setShowStats(true);
    };

    const handleHowToPlayClick = () => {
        setShowHowToPlay(true);
    };

    const handleSelectPlayer = async (player) => {
        const rarityImg = obtenerValorFinal(player.cardtype);

        const playerAttempt = {
            id: player.lineid,
            pace: player.att1,
            shooting: player.att2,
            passing: player.att3,
            dribbling: player.att4,
            defending: player.att5,
            physical: player.att6,
            weakFoot: player.weakfoot,
            skillMoves: player.skillmoves,
            position: player.position,
            club: player.teamname ? player.teamname : player.leaguename,
            nation: player.nationname,
            league: player.leaguename,
            leagueID: player.league,
            rarity: player.cardtype,
            playerImg: player.altimage ? `https://cdn.futwiz.com/assets/img/fc24/faces/${player.altimage}.png` : `https://cdn.futwiz.com/assets/img/fc24/faces/${player.pid}.png`,
            clubImage: player.teamname ? `https://cdn.futwiz.com/assets/img//fc24//badges/${player.club}.png` : `https://cdn.futwiz.com/assets/img/fc24/leagues/${player.league}.png`,
            nationImage: `https://cdn.futwiz.com/assets/img/fc24/flags/${player.nation}.png`,
            leagueImage: `https://cdn.futwiz.com/assets/img/fc24/leagues/${player.league}.png`,
            rarityImage: `https://cdn.futwiz.com/assets/img/fc24/items/${rarityImg}.png`
        };

        const feedbackFromServer = await comparePlayerWithDaily(playerAttempt);

        const newAttempts = [...attempts, { ...playerAttempt, feedback: feedbackFromServer }];
        const completed = feedbackFromServer.correct;

        updateLocalStorage(newAttempts, completed);

    };

    const updateLocalStorage = (newAttempts, completed) => {
        const gameData = {
            day: currentDay, // Utiliza el día actual del estado
            attempts: newAttempts, // Lista de intentos actualizados
            completed: completed // Si se completó el juego
        };
        localStorage.setItem('gameData', JSON.stringify(gameData));
        setAttempts(newAttempts);
        setGameCompleted(completed);


        if (completed) {
            setShowWinModal(true);
            updateStats();
        }
    };

    const updateStats = () => {
        const stats = JSON.parse(localStorage.getItem('playerStats')) || { streak: 0, maxStreak: 0, totalWins: 0, lastDayCompleted: 0, averageAttempts: 0, bestAttempts: 0 };
        stats.totalWins += 1;
        if (currentDay === stats.lastDayCompleted + 1) {
            stats.streak += 1;
        } else {
            stats.streak = 1; // Reinicia la racha si no es el día siguiente
        }
        const totalAttempts = attempts.length + 1;
        const averageAttempts = (stats.averageAttempts * (stats.totalWins - 1) + totalAttempts) / stats.totalWins;
        stats.averageAttempts = averageAttempts;
        if (totalAttempts < stats.bestAttempts || stats.bestAttempts === 0) {
            stats.bestAttempts = totalAttempts;
        }
        if (stats.streak > stats.maxStreak) {
            stats.maxStreak = stats.streak;
        }
        stats.lastDayCompleted = currentDay;
        localStorage.setItem('playerStats', JSON.stringify(stats));
    };

    const comparePlayerWithDaily = async (player) => {
        try {
            const response = await fetch('https://utdle-3zgcdoayfq-uc.a.run.app/compare-player', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(player)
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error al comparar el jugador:', error);
        }
    };

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem('gameData'));
        if (savedData && savedData.day === currentDay) {
            setAttempts(savedData.attempts);
            setGameCompleted(savedData.completed);
        }
    }, [currentDay]);

    // Manejar eventos del socket
    useEffect(() => {
        if (socket) {
            const handleNewDay = (newDailyPlayerCard) => {
                resetGame();
                fetchDailyPlayerCard().then(data => {
                    if (data && !data.message) {
                        setWinnerPlayer(data);
                    }
                });
            };

            socket.on('dailyPlayerCardUpdated', handleNewDay);

            return () => {
                socket.off('dailyPlayerCardUpdated', handleNewDay);
            };
        }
    }, [socket, resetGame]);

    useEffect(() => {
        const statsFromLocalStorage = JSON.parse(localStorage.getItem('playerStats'));
        if (statsFromLocalStorage) {
            setPlayerStats(statsFromLocalStorage);
        }
        if (gameCompleted) {
            setShowWinModal(true);
        }
    }, [gameCompleted]);

    return (
        <div className="Game">
            <header className="Game-header">
                <div className="icon-left">
                    <img src={iconStats} alt="Estadísticas" onClick={handleStatsClick} />
                </div>
                <img src={logo} className="Game-logo" alt="logo" />
                <div className="icon-right">
                    <img src={iconHowToPlay} alt="Cómo jugar" onClick={handleHowToPlayClick} />
                </div>
            </header>

            <div className="title-container">
                <h2 className="title-text">Guess the Player of the Day</h2>
            </div>

            <div className="counter-game-completed-container">
                <h4 className="counter-game-completed-text"><span>{countCompletedGames}</span> people already found out!</h4>
            </div>

            <RevealHints attempts={attempts} player={winnerPlayer} />
            <div className="game-container">
                <table className="attributes-table">
                    <thead>
                        <tr>
                            <th>PLAYER</th>
                            <th>CARD</th>
                            <th>LEAGUE</th>
                            <th>CLUB</th>
                            <th>NATION</th>
                            <th>POS</th>
                            <th>WEAK FOOT</th>
                            <th>SKILLS MOVES</th>
                            <th>PAC</th>
                            <th>SHO</th>
                            <th>PAS</th>
                            <th>DRI</th>
                            <th>DEF</th>
                            <th>PHY</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attempts.map((attempt, index) => (
                            <tr key={index}>
                                <td>
                                    <FeedbackSquare attribute="PlayerImg" value={attempt.playerImg} feedback={attempt.feedback.correct} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Rarity" value={attempt.rarityImage} feedback={attempt.feedback.rarity} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="League" value={attempt.leagueImage} feedback={attempt.feedback.league} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Club" value={attempt.clubImage} feedback={attempt.feedback.club} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Nation" value={attempt.nationImage} feedback={attempt.feedback.nation} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Position" value={attempt.position} feedback={attempt.feedback.position} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Weak Foot" value={attempt.weakFoot} feedback={attempt.feedback.attributes.weakFoot} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Skill Moves" value={attempt.skillMoves} feedback={attempt.feedback.attributes.skillMoves} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Pace" value={attempt.pace} feedback={attempt.feedback.attributes.pace} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Shooting" value={attempt.shooting} feedback={attempt.feedback.attributes.shooting} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Passing" value={attempt.passing} feedback={attempt.feedback.attributes.passing} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Dribbling" value={attempt.dribbling} feedback={attempt.feedback.attributes.dribbling} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Defending" value={attempt.defending} feedback={attempt.feedback.attributes.defending} />
                                </td>
                                <td>
                                    <FeedbackSquare attribute="Physical" value={attempt.physical} feedback={attempt.feedback.attributes.physical} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {gameCompleted ? (
                <div className="game-completed">
                    <h2 className="winner-title">You've completed the game!</h2>
                    <p className="winner-text">Come back tomorrow to play again!</p>
                </div>

            ) : (
                <Search onSelectPlayer={handleSelectPlayer} />
            )}
            <HelperSquare />
            {showStats && (
                <Modal title="✐ Statistics" onClose={() => setShowStats(false)}>
                    <div className="stats-container">
                        <div className="stat">
                            <span className="icon">🏆</span>
                            <span className="label">Games Won:</span>
                            <span className="value">{playerStats.totalWins}</span>
                        </div>
                        <div className="stat">
                            <span className="icon">🔍</span>
                            <span className="label">Average Guesses:</span>
                            <span className="value">{playerStats.averageAttempts.toFixed(1)}</span>
                        </div>
                        <div className="stat">
                            <span className="icon">🌟</span>
                            <span className="label">Best Guess:</span>
                            <span className="value">{playerStats.bestAttempts}</span>
                        </div>
                        <div className="stat">
                            <span className="icon">⚡</span>
                            <span className="label">Current Streak:</span>
                            <span className="value">{playerStats.streak}</span>
                        </div>
                        <div className="stat">
                            <span className="icon">🔥</span>
                            <span className="label">Max Streak:</span>
                            <span className="value">{playerStats.maxStreak}</span>
                        </div>
                    </div>
                    <div className="share">
                        <TwitterShare playerStats={playerStats} />
                        <CopyToClipboard playerStats={playerStats} />
                    </div>
                </Modal>)}
            {showHowToPlay && (
                <Modal title="HOW TO PLAY?" onClose={() => setShowHowToPlay(false)}>
                    <p>Guess today's player from the EA FC "Ultimate Team" game. It changes every 24 hours.</p>
                    <Counter currentDay={currentDay} />
                    <p>Select a random player to start and be able to view their statistics.</p>
                    <p>The color of the cells will change depending on how close you are to finding the player.</p>
                    <p><span className="green-modal">Green:</span> Correct</p>
                    <p><span className="dark-yellow-modal">Dark Yellow:</span> The value is higher than what you entered</p>
                    <p><span className="light-yellow-modal">Light Yellow:</span> The value is lower than what you entered</p>
                    <p><span className="red-modal">Red:</span> There is no match between what you selected and the value</p>

                </Modal>
            )}
            {showWinModal && (
                <WinModal
                    player={winnerPlayer}
                    onClose={() => setShowWinModal(false)}
                    showStats={() => setShowStats(true)}
                    streak={playerStats.streak}
                    currentDay={currentDay}
                    attempts={attempts}
                />
            )}

        </div>
    );
}

export default Game;
