import React, { useState, useEffect } from 'react';
import './Counter.css';

const Counter = ( {currentDay }) => {
    const [countdown, setCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const calculateCountdown = () => {
            const now = new Date();
            const utcMidnight = new Date(
                Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0)
            );
            const targetTime = new Date(utcMidnight.getTime() + 24 * 60 * 60 * 1000);
            const timeRemaining = targetTime - now;
            const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
            setCountdown({ hours, minutes, seconds });
        };

        const interval = setInterval(calculateCountdown, 1000);

        calculateCountdown(); // Calcula el tiempo restante al cargar el componente

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="countdown">
            <div className='current-day'>Current Day: #{currentDay}</div>
            <div>Next Player in :</div>
            <div className="countdown-timer">
                <span className="number-change">{countdown.hours.toString().padStart(2, '0')}</span>
                <span>:</span>
                <span className="number-change">{countdown.minutes.toString().padStart(2, '0')}</span>
                <span>:</span>
                <span className="number-change">{countdown.seconds.toString().padStart(2, '0')}</span>
            </div>
            <div className='time-zone'>Time Zone: UTC+0</div>
        </div>
    );
};

export default Counter;
