export function determineGender(leagueNumber, playerName) {
    const womenLeagues = [2231, 2215, 2228, 2222, 2216, 2230, 2229, 2233, 2218, 2232, 2236, 2221];
    const specialWomenPlayers = ["Mia Hamm", "Birgit Prinz", "Homare Sawa", "Camille Abily", "Kelly Smith"];

    if (womenLeagues.includes(Number(leagueNumber))) { // Convierte a número para la comparación
        return 'Woman';
    }

    if (leagueNumber === 2118 && specialWomenPlayers.includes(playerName)) {
        return 'Woman';
    }

    return 'Man';
}

