export const obtenerValorFinal = (cardtype) => {
    // Descomponer la cadena en partes usando el guión como separador
    const partes = cardtype.split('-');

    // Revisa el número de partes y toma decisiones en base a ello
    if (partes.length === 3) {
        // Revisa si es el caso especial 'goldif'
        if (partes[2] === 'goldif') {
            return 'gold-if';
        } else if (partes[2] === 'icon') {
            return 'icon';
        } else if (partes[2] === 'futhero') {
            return 'hero';
        } else if (!isNaN(partes[2])) {
            // Para casos numéricos como "card-24-68"
            return partes[2];
        } else {
            // Otros casos con tres partes (ejemplo: "card-24-gold")
            const tipo = partes[2]; // gold, silver, bronze
            return `fc${partes[1]}-${tipo}-2`; // Asumiendo que termina en "-2" por defecto
        }
    } else if (partes.length > 3) {
        // Para casos más complejos, construye el valor final según tus reglas
        const tipo = partes[2]; // gold, silver, bronze
        const numero = partes.includes('nr') ? '1' : '2';
        return `fc${partes[1]}-${tipo}-${numero}`;
    }

    // Si la cadena no coincide con los formatos conocidos, devuelve un valor por defecto o error
    return 'Valor no reconocido';
}